module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"name":"blackAndWhite","anchor":"#1034a6","anchorVisited":"#440381","borderDark":"#888c8f","borderDarkest":"#000000","borderLight":"#dfe0e3","borderLightest":"#888c8f","canvas":"#ffffff","canvasText":"#000000","canvasTextDisabled":"#888c8f","canvasTextDisabledShadow":"#ffffff","canvasTextInvert":"#ffffff","checkmark":"#000000","checkmarkDisabled":"#888c8f","flatDark":"#9e9e9e","flatLight":"#d8d8d8","focusSecondary":"#fefe03","headerBackground":"#000000","headerNotActiveBackground":"#ffffff","headerNotActiveText":"#000000","headerText":"#ffffff","hoverBackground":"#000000","material":"#ffffff","materialDark":"#9a9e9c","materialText":"#000000","materialTextDisabled":"#888c8f","materialTextDisabledShadow":"#ffffff","materialTextInvert":"#ffffff","progress":"#000000","tooltip":"#fefbcc"},"dark":{"name":"highContrast","anchor":"#07ff00","anchorVisited":"#8e0284","borderDark":"#888c8f","borderDarkest":"#ffffff","borderLight":"#dfe0e3","borderLightest":"#ffffff","canvas":"#353535","canvasText":"#ffffff","canvasTextDisabled":"#888c8f","canvasTextDisabledShadow":"#ffffff","canvasTextInvert":"#ffffff","checkmark":"#ffffff","checkmarkDisabled":"#888c8f","flatDark":"#9e9e9e","flatLight":"#d8d8d8","focusSecondary":"#fefe03","headerBackground":"#8e0284","headerNotActiveBackground":"#7f7f7f","headerNotActiveText":"#ced0cf","headerText":"#ffffff","hoverBackground":"#8e0284","material":"#000000","materialDark":"#9a9e9c","materialText":"#ffffff","materialTextDisabled":"#888c8f","materialTextDisabledShadow":"#ffffff","materialTextInvert":"#ffffff","progress":"#8e0284","tooltip":"#fefbcc"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/hires-muscarian.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d70a1b609b3f0d564ae6a81d73661c4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_US","url":"https://www.muscarian.com/","site_name":"Muscarian Games","images":[{"url":"https://www.muscarian.com/hires-muscarian.png","width":800,"height":600,"alt":"Muscarian Games Logo"}]},"twitter":{"handle":"@muscarian_games","site":"@muscarian_games","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
